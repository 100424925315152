import React from 'react';
import { required } from 'react-admin';
import WealthCreateGuesser from '../../base/components/guesser/wealth-create.guesser';
import CurrenciesReference from '../../base/components/reference-fields/currencies';
import FeaturesInput from '../brand/components/features.input';

const GroupCreate = props => {
  const transform = data => {
    const dataBody = {
      ...data,
    };
    if (dataBody?.features) {
      dataBody.featureIds = dataBody.features;
      delete dataBody.features;
    }
    return dataBody;
  };

  return (
    <WealthCreateGuesser
      {...props}
      transform={transform}
      excludedFields={['featureIds']}
      customFields={['currencies']}
      fieldOrder={['name', 'managerEmail', 'features', 'currencies', 'desc', 'enabled']}
    >
      <FeaturesInput
        label="resources.feature-flag.name"
        source="features"
        {...props}
      />
      <CurrenciesReference
        source="currencies"
        validate={required()}
        {...props}
      />
    </WealthCreateGuesser>
  );
};

export default GroupCreate;
