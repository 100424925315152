import React from 'react';
import { ReferenceArrayInput, useRecordContext } from 'react-admin';
import { useFormState } from 'react-final-form';
import EnsurePermission from '../../../base/components/EnsurePermission';
import resourceSlug from '../../../constant/resource-slug';
import { AutocompleteArrayInput } from '../../../base/components/ra/inputs';

const FeaturesInput = props => {
  const { initialGroupId } = props;

  const { values } = useFormState();
  const record = useRecordContext();
  const groupId = values.group?.id || record.group?.id || initialGroupId;

  return (
    <EnsurePermission
      canRead
      resource={resourceSlug.FEATURES}
    >
      <ReferenceArrayInput
        label="resources.feature-flag.name"
        source="features"
        reference={resourceSlug.FEATURES}
        filter={{
          'groupId||$eq': groupId || undefined,
          isActive: true,
        }}
        format={items => items?.map(item => item?.id || item)}
        filterToQuery={value => ({
          'name||$cont': value || '',
        })}
        {...props}
      >
        <AutocompleteArrayInput variant="outlined" />
      </ReferenceArrayInput>
    </EnsurePermission>
  );
};

export default FeaturesInput;
