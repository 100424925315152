import { Box, Chip, Drawer, IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import { FunctionField, Labeled, RecordContext, SelectField, TextField, useLocale, useTranslate } from 'react-admin';
import { Close } from '@material-ui/icons';
import { ShowSection } from '../../../../../base/components/guesser/wealth-show.guesser';
import { DateField } from '../../../../../base/components/ra/fields';
import { formatNumber } from '../../../../../services/util/formatNumber';
import { PLAYER_BATCH_SOURCE_TYPE, PLAYER_BATCH_STATUS } from '../../../../../constant/campaign';

const useStyles = makeStyles({
  wrapper: {
    padding: '36px 28px',
    "& > [class*='sectionTitle']": {
      fontSize: 17,
    },
  },
  row: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 250px)',
    gap: 18,
    '& .ra-field': {
      width: '100%',
      minWidth: '100%',
    },
    gridTemplateAreas: `
    "one two"
    "three four"
    "five six"
    "seven eight"
    "nine nine"
    `,
    '& > :nth-child(1)': {
      gridArea: 'one',
    },
    '& > :nth-child(2)': {
      gridArea: 'two',
    },
    '& > :nth-child(3)': {
      gridArea: 'three',
    },
    '& > :nth-child(4)': {
      gridArea: 'four',
    },
    '& > :nth-child(5)': {
      gridArea: 'five',
    },
    '& > :nth-child(6)': {
      gridArea: 'six',
    },
    '& > :nth-child(7)': {
      gridArea: 'seven',
    },
    '& > :nth-child(8)': {
      gridArea: 'eight',
    },
    '& > :nth-child(9)': {
      gridArea: 'nine',
    },
    '& > :nth-child(10)': {
      gridArea: 'ten',
    },
  },
  root: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
});

const PlayerBatchDetailsDrawer = ({
  batch, onClose,
}) => {
  const locale = useLocale();
  const t = useTranslate();
  const classes = useStyles();

  return (
    <Drawer
      className={classes.root}
      open
      anchor="right"
      onClose={onClose}
    >
      <IconButton
        className={classes.closeButton}
        onClick={() => onClose()}
      >
        <Close />
      </IconButton>
      <RecordContext.Provider value={batch}>
        <ShowSection
          className={classes.wrapper}
          classes={{
            root: classes.row,
          }}
          title={t('resources.campaign.player-batch-details')}
        >
          <TextField
            source="batchCode"
            label="resources.campaign.fields.player-batch-code"
          />
          <Labeled label="resources.campaign.fields.created-date">
            <DateField
              source="created"
            />
          </Labeled>
          <TextField
            source="actor"
            label="resources.campaign.fields.created-by"
          />
          <SelectField
            source="source"
            label="resources.campaign.fields.source-type"
            choices={Object.values(PLAYER_BATCH_SOURCE_TYPE).map(source => ({
              id: source,
              name: t(`resources.campaign.player-batch-source-type.${source}`),
            }))}
          />
          <Labeled label="resources.campaign.fields.scheduled-date">
            <DateField
              source="issuedDate"
            />
          </Labeled>
          <FunctionField
            label="resources.campaign.fields.bet-amount"
            render={record => formatNumber('en', record.betAmount)}
          />
          <FunctionField
            label="resources.campaign.fields.valid-period"
            render={record => t(`resources.campaign.unit-content.${record.freeSpinExpireUnit}`, {
              smart_count: record.freeSpinExpireIn,
            })}
          />
          <SelectField
            source="status"
            label="resources.campaign.fields.status"
            choices={Object.values(PLAYER_BATCH_STATUS).map(status => ({
              id: status,
              name: t(`resources.campaign.player-batch-status.${status}`),
            }))}
            sortable={false}
          />
          <FunctionField
            label="resources.campaign.fields.games"
            render={record => (
              <Box
                display="flex"
                flexWrap="wrap"
                sx={{
                  gap: 4,
                }}
              >
                {record.games?.map(game => <Chip label={game[locale]} />)}
              </Box>
            )}
          />
        </ShowSection>
      </RecordContext.Provider>

    </Drawer>
  );
};

export default PlayerBatchDetailsDrawer;
