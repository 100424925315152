/* eslint-disable  */
import React from 'react';
import {
  ArrayField,
  TextField,
  useListContext, usePermissions, useTranslate,
} from 'react-admin';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import { transformIdValue } from '../../services/util/stringUtil';
import { ID_EMPTY } from '../../constant';
import resourceSlug from '../../constant/resource-slug';
import ArrayWithPaginationField from '../../base/components/ra/fields/arrayWithPagination.field';
import CustomLinkField from '../../base/components/ra/fields/customLink.field';
import { Box } from '@material-ui/core';
import FeaturesTable from '../brand/components/features.table';
import { useAuthUser } from '../../base/hooks';

const GroupShow = (props) => {
  const { permissions } = usePermissions();
  const user = useAuthUser();
  const translate = useTranslate();

  const hasFeaturesReadPermission = user?.superman
    || (Array.isArray(permissions) && !!permissions?.find(item => item.resource.name === resourceSlug.FEATURES)?.read);
  return (
    <WealthShowGuesser
      excludeFields={['brands', 'currencies', 'features']}
      sections={[
        hasFeaturesReadPermission && (
          <FeaturesTable
            title={translate('resources.feature-flag.name')}
            source="features"
          />
        ),
        <ArrayWithPaginationField source="brands" label={false}>
          <CustomLinkField
            sortable={false}
            source="id"
            href={(record) => `/#/${resourceSlug.BRAND}/${record.id}/show`}
            title={(record) => (record.id ? transformIdValue(record.id) : ID_EMPTY)}
          />
          <TextField source="name" sortable={false} label={'resources.group.fields.name'} />
          <TextField source="code" sortable={false} />
          <ArrayField source="features" label={'resources.feature-flag.name'}>
            <BrandFeatureFlags/>
          </ArrayField>
        </ArrayWithPaginationField>,
        <ArrayWithPaginationField source="currencies" label={false}>
          <CustomLinkField
            sortable={false}
            source="id"
            href={(record) => `/#/${resourceSlug.CURRENCY}/${record.id}/show`}
            title={(record) => (record.id ? transformIdValue(record.id) : ID_EMPTY)}
          />
          <TextField source="name" sortable={false} label={'resources.group.fields.name'} />
          <TextField source="code" sortable={false} />
        </ArrayWithPaginationField>,
      ]}
      {...props}
    />
  );
};

const BrandFeatureFlags = () => {
  const { data } = useListContext();
  const features = Object.values(data || {})
    return (
        <div>
            {features?.map(feature => (
                <Box width="fit-content" display="flex" alignItems="center" sx={{ gap: 4, "&:before": { content: '""', display: "block", width: 5, height: 5, borderRadius: 1000, background: "black"}}} key={feature.id}>{feature.name}</Box>
            ))}
        </div>
    );

    return null
}

export default GroupShow;
