/* eslint-disable  */
import React, { useEffect, useState } from 'react';
import { useTranslate, FormDataConsumer } from 'ra-core';
import { useNotify, useUpdate } from 'react-admin';
import { BooleanInput } from '../../base/components/ra/inputs';
import { makeStyles, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useField } from 'react-final-form';
import useError from '../../base/hooks/useError';
import WealthEditGuesser from '../../base/components/guesser/wealth-edit.guesser';
import WealthInputWrapper from '../../base/components/guesser/wealth-input-wrapper';
import ConfirmationDialog from './components/confirm-dialog';
import FeaturesInput from '../brand/components/features.input';

const useStyles = makeStyles((theme) => ({
  noticeDisableAllBrands: {
    color: theme.palette.error.main,
  },
}));

const RESOURCE_NAME = 'group';

const EnableInputWrapper = ({ children }) => {
  useField('enabled');
  return children;
};

const GroupEdit = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const history = useHistory();
  const { notifyError } = useError();

  const [isDisableBrandsDialog, setDisableBrandsDialog] = useState(false);
  const [isAgreeDisableAllBrands, setAgreeDisableAllBrands] = useState(false);
  const [isEnableConfirm, setEnableConfirm] = useState(false);

  const onUpdateSuccess = (response) => {
    const { data } = response;
    const groupId = data?.id;

    const translatedGroupName = translate('resources.group.name');
    notify('ra.notification.updated', 'success', {
      smart_name: translatedGroupName,
    });

    if (groupId) {
      history.push(`/${RESOURCE_NAME}/${groupId}/show`);
    }
  };

  const [update, { loading: updateLoading, error }] = useUpdate(undefined, undefined, undefined, undefined, {
    onSuccess: onUpdateSuccess,
  });

  const saveGroupData = (values) => {
    const { enabled, managerEmail, name, desc, id, currencies, features } = values;
    const payload = {
      id,
      data: {
        enabled,
        managerEmail,
        name,
        desc,
        currencies,
        featureIds: features,
      },
    };

    if (!enabled && isAgreeDisableAllBrands) {
      payload.data = {
        ...payload.data,
        disableBrands: true,
      };
    }

    update({ resource: RESOURCE_NAME, payload });
  };

  useEffect(() => {
    if (!updateLoading && error) {
      notifyError(error);
    }
  }, [updateLoading, error]);

  return (
    <>
      <WealthEditGuesser
        {...props}
        onCustomSave={saveGroupData}
        customFields={['enabled']}
        excludedFields={["featureIds"]}
        fieldOrder={['name', 'managerEmail', 'features', 'currencies', 'desc', 'enabled']}
      >
        <FeaturesInput
          source="features"
          label="resources.feature-flag.name"
        />
        <WealthInputWrapper source="enabled">
          <FormDataConsumer>
            {({ formData }) => (
              <EnableInputWrapper>
                <BooleanInput
                  label="resources.group.fields.enabled"
                  source="enabled"
                  onChange={(isEnabled) => {
                    if (isEnabled === false) {
                      setDisableBrandsDialog(true);
                    } else {
                      setAgreeDisableAllBrands(false);
                    }
                  }}
                />
                {!formData.enabled && isAgreeDisableAllBrands && (
                  <p className={classes.noticeDisableAllBrands}>{translate('ra.text.disableGroupNoticeText')}</p>
                )}
              </EnableInputWrapper>
            )}
          </FormDataConsumer>
        </WealthInputWrapper>
      </WealthEditGuesser>
      {isDisableBrandsDialog ? (
        <ConfirmationDialog
          open={true}
          onDeny={() => {
            setAgreeDisableAllBrands(false);
            setDisableBrandsDialog(false);
          }}
          onConfirm={() => {
            setAgreeDisableAllBrands(true);
            setDisableBrandsDialog(false);
          }}
          disableConfirm={!isEnableConfirm}
          title={
            <FormGroup>
              <FormControlLabel
                label={translate('ra.text.disableGroupCheckboxLabel')}
                control={
                  <Checkbox
                    onChange={(event) => {
                      setEnableConfirm(event.target.checked);
                    }}
                  />
                }
              />
            </FormGroup>
          }
          dialogTitle={translate('ra.text.disableGroupDialogTitle')}
        />
      ) : null}
    </>
  );
};

export default GroupEdit;
